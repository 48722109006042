import { Record, Union } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Types.js";
import { array_type, string_type, record_type, bool_type, union_type, class_type } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Reflection.js";
import { parse, toNumber, fromParts, op_Division, op_Multiply } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Decimal.js";
import Decimal from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Decimal.js";
import { toDecimal } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/BigInt.js";
import { FPDECIMAL_PERCENT_BASE, FPDECIMAL_ONE } from "./Common.fs.js";
import { printf, toText } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/String.js";
import { fromString, uint64, oneOf, bool, string, int, object } from "../../../backoffice/client/src/fable_modules/Thoth.Json.10.1.0/Decode.fs.js";
import { uncurry } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/Util.js";
import { ofArray } from "../../../backoffice/client/src/fable_modules/fable-library.3.7.9/List.js";

export class InjectiveProductStrike extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Relative", "Fixed"];
    }
}

export function InjectiveProductStrike$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveProduct.InjectiveProductStrike", [], InjectiveProductStrike, () => [[["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]]]);
}

export class InjectiveProductStrikeType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RelativeStrike", "AbsoluteStrike"];
    }
}

export function InjectiveProductStrikeType$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveProduct.InjectiveProductStrikeType", [], InjectiveProductStrikeType, () => [[], []]);
}

export class VanillaProduct extends Record {
    constructor(IsPut, Strike) {
        super();
        this.IsPut = IsPut;
        this.Strike = Strike;
    }
}

export function VanillaProduct$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProduct.VanillaProduct", [], VanillaProduct, () => [["IsPut", bool_type], ["Strike", InjectiveProductStrike$reflection()]]);
}

export class ProductType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Vanilla"];
    }
}

export function ProductType$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveProduct.ProductType", [], ProductType, () => [[["Item", VanillaProduct$reflection()]]]);
}

export class InjectiveProductKind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Call", "Put"];
    }
}

export function InjectiveProductKind$reflection() {
    return union_type("ExoticMarkets.Domain.InjectiveProduct.InjectiveProductKind", [], InjectiveProductKind, () => [[], []]);
}

export function InjectiveProductKind__GetExpectation(this$) {
    if (this$.tag === 1) {
        return "Bearish";
    }
    else {
        return "Bullish";
    }
}

export class InjectiveProduct extends Record {
    constructor(Id, Duration, OracleBase, OracleQuote, BaseDenom, QuoteDenom, ProductType) {
        super();
        this.Id = Id;
        this.Duration = Duration;
        this.OracleBase = OracleBase;
        this.OracleQuote = OracleQuote;
        this.BaseDenom = BaseDenom;
        this.QuoteDenom = QuoteDenom;
        this.ProductType = ProductType;
    }
}

export function InjectiveProduct$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProduct.InjectiveProduct", [], InjectiveProduct, () => [["Id", string_type], ["Duration", class_type("System.UInt64")], ["OracleBase", string_type], ["OracleQuote", string_type], ["BaseDenom", string_type], ["QuoteDenom", string_type], ["ProductType", ProductType$reflection()]]);
}

export function InjectiveProduct__GetKind(this$) {
    if (this$.ProductType.fields[0].IsPut) {
        return new InjectiveProductKind(1);
    }
    else {
        return new InjectiveProductKind(0);
    }
}

export function InjectiveProduct__GetStrike(this$) {
    return this$.ProductType.fields[0].Strike;
}

export function InjectiveProduct__GetStrikeWithSpot_32C73145(this$, spot) {
    const matchValue_1 = this$.ProductType.fields[0].Strike;
    if (matchValue_1.tag === 1) {
        return matchValue_1.fields[0];
    }
    else {
        return op_Multiply(op_Division(matchValue_1.fields[0], toDecimal(FPDECIMAL_ONE)), spot);
    }
}

export function InjectiveProduct__GetPercentageStrikeWithSpot_32C73145(this$, spot) {
    const matchValue_1 = this$.ProductType.fields[0].Strike;
    if (matchValue_1.tag === 1) {
        try {
            return op_Division(op_Multiply(matchValue_1.fields[0], fromParts(100, 0, 0, false, 0)), spot);
        }
        catch (matchValue_2) {
            return fromParts(100, 0, 0, false, 0);
        }
    }
    else {
        return op_Division(matchValue_1.fields[0], toDecimal(FPDECIMAL_PERCENT_BASE));
    }
}

export function InjectiveProduct__GetFormattedStrike(this$) {
    let copyOfStruct;
    const matchValue_1 = this$.ProductType.fields[0].Strike;
    if (matchValue_1.tag === 1) {
        return "$ " + ((copyOfStruct = toNumber(matchValue_1.fields[0]), copyOfStruct.toString()));
    }
    else {
        const relativeStrike = toNumber(op_Division(matchValue_1.fields[0], toDecimal(FPDECIMAL_PERCENT_BASE)));
        return toText(printf("%0.01f%%"))(relativeStrike);
    }
}

export function InjectiveProduct__GetDepositDenom(this$) {
    const matchValue = InjectiveProduct__GetKind(this$);
    if (matchValue.tag === 1) {
        return this$.QuoteDenom;
    }
    else {
        return this$.BaseDenom;
    }
}

export function InjectiveProduct__GetStrikeType(this$) {
    if (this$.ProductType.fields[0].Strike.tag === 1) {
        return new InjectiveProductStrikeType(1);
    }
    else {
        return new InjectiveProductStrikeType(0);
    }
}

export function InjectiveProduct_GetProductName(tokenSymbol, product) {
    const upDown = product.ProductType.fields[0].IsPut ? "Down" : "Up";
    return `DCN-${upDown}-${tokenSymbol}`;
}

export function InjectiveProduct_GetEscrowProductName(tokenSymbol, product) {
    const cp = product.ProductType.fields[0].IsPut ? "Put" : "Call";
    return `${tokenSymbol} ${cp}`;
}

export function InjectiveProduct_GetProductNameDescription_Z4C3DC210(product) {
    const upDown = product.ProductType.fields[0].IsPut ? "Downside" : "Upside";
    return `Dual Invest. ${upDown}`;
}

export class Id extends Record {
    constructor(Id) {
        super();
        this.Id = Id;
    }
}

export function Id$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProduct.Id", [], Id, () => [["Id", string_type]]);
}

export class Ids extends Record {
    constructor(Ids) {
        super();
        this.Ids = Ids;
    }
}

export function Ids$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProduct.Ids", [], Ids, () => [["Ids", array_type(string_type)]]);
}

export class QueryProduct extends Record {
    constructor(GetProduct) {
        super();
        this.GetProduct = GetProduct;
    }
}

export function QueryProduct$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProduct.QueryProduct", [], QueryProduct, () => [["GetProduct", Id$reflection()]]);
}

export class QueryProducts extends Record {
    constructor(GetProducts) {
        super();
        this.GetProducts = GetProducts;
    }
}

export function QueryProducts$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProduct.QueryProducts", [], QueryProducts, () => [["GetProducts", Ids$reflection()]]);
}

export class InjectiveProductRaw extends Record {
    constructor(Duration, OracleBase, OracleQuote, BaseDenom, QuoteDenom, ProductType) {
        super();
        this.Duration = Duration;
        this.OracleBase = OracleBase;
        this.OracleQuote = OracleQuote;
        this.BaseDenom = BaseDenom;
        this.QuoteDenom = QuoteDenom;
        this.ProductType = ProductType;
    }
}

export function InjectiveProductRaw$reflection() {
    return record_type("ExoticMarkets.Domain.InjectiveProduct.InjectiveProductRaw", [], InjectiveProductRaw, () => [["Duration", class_type("System.UInt64")], ["OracleBase", string_type], ["OracleQuote", string_type], ["BaseDenom", string_type], ["QuoteDenom", string_type], ["ProductType", ProductType$reflection()]]);
}

export function InjectiveProductRaw__ToProduct_Z721C83C5(this$, id) {
    return new InjectiveProduct(id, this$.Duration, this$.OracleBase, this$.OracleQuote, this$.BaseDenom, this$.QuoteDenom, this$.ProductType);
}

export const relativeStrikeDecoder = (path_2) => ((v) => object((get$) => {
    let objectArg_3, objectArg_2, objectArg_1, objectArg;
    try {
        return new InjectiveProductStrike(0, op_Multiply(parse((objectArg = get$.Required, objectArg.Field("relative", string))), toDecimal(FPDECIMAL_ONE)));
    }
    catch (matchValue) {
        try {
            return new InjectiveProductStrike(0, op_Multiply(new Decimal((objectArg_1 = get$.Required, objectArg_1.Field("relative", uncurry(2, int)))), toDecimal(FPDECIMAL_ONE)));
        }
        catch (matchValue_1) {
            try {
                return new InjectiveProductStrike(1, parse((objectArg_2 = get$.Required, objectArg_2.Field("absolute", string))));
            }
            catch (matchValue_2) {
                return new InjectiveProductStrike(1, new Decimal((objectArg_3 = get$.Required, objectArg_3.Field("absolute", uncurry(2, int)))));
            }
        }
    }
}, path_2, v));

export const fixedStrikeDecoder = (path_1) => ((v) => object((get$) => {
    let objectArg_1, objectArg;
    try {
        return new InjectiveProductStrike(1, parse((objectArg = get$.Required, objectArg.Field("absolute", string))));
    }
    catch (matchValue) {
        try {
            return new InjectiveProductStrike(1, new Decimal((objectArg_1 = get$.Required, objectArg_1.Field("absolute", uncurry(2, int)))));
        }
        catch (matchValue_1) {
            throw (new Error("failed in fixedStrikeDecoder"));
        }
    }
}, path_1, v));

export const vanillaProductDecoder = (path_2) => ((v) => object((get$) => {
    let objectArg, objectArg_1;
    return new VanillaProduct((objectArg = get$.Required, objectArg.Field("is_put", bool)), (objectArg_1 = get$.Required, objectArg_1.Field("strike", (path_1, value_1) => oneOf(ofArray([relativeStrikeDecoder, fixedStrikeDecoder]), path_1, value_1))));
}, path_2, v));

export const productTypeDecoder = (path) => ((v) => object((get$) => {
    let objectArg;
    return new ProductType(0, (objectArg = get$.Required, objectArg.Field("vanilla", uncurry(2, vanillaProductDecoder))));
}, path, v));

export const injectiveProductRawDecoder = (path_4) => ((v) => object((get$) => {
    let objectArg, objectArg_1, objectArg_2, objectArg_3, objectArg_4, objectArg_5;
    return new InjectiveProductRaw((objectArg = get$.Required, objectArg.Field("duration", uncurry(2, uint64))), (objectArg_1 = get$.Required, objectArg_1.Field("oracle_base", string)), (objectArg_2 = get$.Required, objectArg_2.Field("oracle_quote", string)), (objectArg_3 = get$.Required, objectArg_3.Field("base_denom", string)), (objectArg_4 = get$.Required, objectArg_4.Field("quote_denom", string)), (objectArg_5 = get$.Required, objectArg_5.Field("product_type", uncurry(2, productTypeDecoder))));
}, path_4, v));

export function deserializeProduct(id, data) {
    const raw = fromString(uncurry(2, injectiveProductRawDecoder), data);
    if (raw.tag === 1) {
        throw (new Error("failed in product deserialization"));
    }
    else {
        return InjectiveProductRaw__ToProduct_Z721C83C5(raw.fields[0], id);
    }
}

